<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>用户管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>用户列表</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>提现管理</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('2')">
          <span>余额明细</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>余额明细</span>
        </div>
        <div class="mt-20">
          <el-table :data="tableData.data" border style="width: 100%">
            <el-table-column
              prop="id"
              label="ID"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="user.nickName"
              label="微信昵称"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="describe"
              label="余额变动场景"
              width="150"
              align="center"
            >
            <template slot-scope="scope">
              <span v-if="scope.row.scene == 10">卖出收入余额</span>
              <span v-if="scope.row.scene == 20">提现支出余额</span>
              <span v-if="scope.row.scene == 30">提现增加冻结金额</span>
              <span v-if="scope.row.scene == 40">分销收入余额</span>
              <span v-if="scope.row.scene == 50">提现成功扣除冻结金额</span>
              <span v-if="scope.row.scene == 60">提现失败返还余额</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="money"
              label="变动金额"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="money"
              label="操作人员ID"
              width="150"
              align="center"
            >
            <template slot-scope="scope">
              {{scope.row.operation_id == 0 ? '无操作人员' : scope.row.operation_id}}
            </template>
            </el-table-column>
            <el-table-column
              prop="describe"
              label="描述"
              width="300"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="created_at"
              label="变动时间"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="bottom-paging">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-sizes="[5, 10, 20, 30, 100]"
            :page-size="5"
            layout="total, prev, pager, next, sizes"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          money: 123,
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          money: 123,
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          money: 123,
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
          money: 123,
        },
      ],
      isCollapse: false,
      inpuValue: "",
      page:1,
      num:10
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList(){
      this.$get('get/backend_balance_info',{
        page:this.page,
        num:this.num,
        user_id:this.$route.query.id ? this.$route.query.id : ''
      }).then(res =>{
        this.tableData = res.data.data;
        console.log(res);
      })
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/user") {
        this.$router.push("/user");
      } else if (index == 1 && this.$route.path != "/withdrawal") {
        this.$router.push("/withdrawal");
      }  else if (index == 2 && this.$route.path != "/balance") {
        this.$router.push("/balance");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleEdit(index, row) {
      this.$router.push({
        name: "userinfo",
        params: {
          row: row,
        },
      });
      //   console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .date-search {
    margin-top: 30px;
    text-align: right;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 300px;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
